<!--主页-->
<template>
  <!--通知公告s-->
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="12" :md="11" :lg="11" >
        <div class="box-tit">通知公告</div>
        <div class="nlist">
          <ul>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
          </ul>
        </div>
      </el-col>
      <el-col :sm="12" :md="11" :lg="11">
        <div class="box-tit">资源搜索</div>
        <div class="sou_box">
            <input id="key" type="text" maxlength="40" onclick="if (this.value == '请输入关键词搜索') this.value = '';" onblur="if (this.value =='') this.value='请输入关键词'" value="请输入关键词" />
            <span onclick="Search()">搜 索</span>
        </div>
        <div class="sou_tag">
          <p>热门资源</p>
            <span>资源名称</span><span>资源名称</span><span>资源热点检索</span><span>资源名称热点检索</span><span>精典案例项目名称</span><span>资源</span>
            <span>资源名称</span><span>资源名称</span><span>资源热点检索</span><span>资源名称热点检索</span><span>精典案例项目名称</span><span>资源</span><span>资源名</span>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>
  <!--通知公告e-->
  <!--产学研资源s-->
  <div class="box-p main_bgcolor">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="24" :md="22" :lg="22" >
        <div class="box-tit1">产学研资源</div>
        <el-row :gutter="20">
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1004.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1017.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1004.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :sm="12" :md="6" :lg="6">
            <el-card :body-style="{ padding: '0px' }" class="card">
              <img src="@/assets/images/1017.jpg" />
              <div class="card-body">
                <div class="card-title">产学研资源标题名称</div>
                <p class="card-text">欧洲精英MINI拥有超乎想象的迷你设计，只有常规壁挂炉的一半尺寸，瘦身安装空间。出色的采暖和舒适热水供应性能，轻松满足小户型的使用需求!</p>
                <div class="card-bottom">
                  <time class="card-time">2022-07-04 18:40</time>
                  <a href="#" target="_blank"><el-button type="primary" plain>详细内容</el-button></a>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>        
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>
  <!--产学研资源e-->
  <!-- 资源公示s -->
  <div class="box-p">
    <el-row :gutter="0">
      <el-col :sm="0" :md="1" :lg="1" ></el-col>
      <el-col :sm="12" :md="11" :lg="11" >
        <div class="box-tit">资源公示</div>
        <div class="nlist">
          <ul>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
            <li><span>· </span><a href="#" target="_blank">精典案例项目名称--精典案例项目名称--精典案例项目名称<span>2022-05-31</span></a></li>
          </ul>
        </div>
      </el-col>
      <el-col :sm="12" :md="11" :lg="11">
        <div class="box-tit">快速入口</div>
        <div class="link">
            <a href="#" target="_blank">资源名称</a><a href="#" target="_blank">资源名称</a><a href="#" target="_blank">资源热点检索</a><a href="#" target="_blank">资源名称热点检索</a><a href="#" target="_blank">精典案例项目名称</a>
            <a href="#" target="_blank">资源名称</a><a href="#" target="_blank">资源名称</a><a href="#" target="_blank">资源热点检索</a><a href="#" target="_blank">资源名称热点检索</a><a href="#" target="_blank">精典案例项目名称</a>
            <a href="#" target="_blank">精典案例项目名称</a><a href="#" target="_blank">精典案例项目名称</a>
        </div>
      </el-col>
      <el-col :sm="0" :md="1" :lg="1"></el-col>
    </el-row>
  </div>
  <!--资源公示e-->
</template>

<script>
import {formatDate} from '@/utils/datetime_utils.js'
export default {
  name: 'index',
  data () {
    return {
      startTime: 1654012800000,
      messageData: []
    }
  },
  mounted () {
    // document.title = '云南省大学科技园'
    // this.getMessageData()
  },
  // filters:{
  //   formatDate(time){
  //       var data = new Date(time);
  //       return formatDate(data,'yyyy MM dd hh:mm:ss')
  //   }
  // },
  methods: {
    async getMessageData () {
      // this.tableLoading = true
      const params = '?status=0&size=6' 
      const { data: ret } = await this.$http.get(this.$api.messagelist + params)     
      console.log('ret=', ret)
      if (ret.code === 1) {
        this.messageData = ret.data.records
        console.log('rows=', this.messageData)
      }
      // this.tableLoading = false
    },
    // 显示时间格式'yyyy-MM-dd hh:mm:ss'
    formatDate(time){
      var data = new Date(time)
      return formatDate(data,'yyyy-MM-dd')
    }
  }
}
</script>

<style>

</style>
